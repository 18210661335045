export const environment: any = {
    name: 'develop',
    production: true,
    apiUrl: 'https://consumer-api.clickdealer.dev',
    assetsUrl: 'https://dev-click-dealer-consumer-assets.s3.eu-west-2.amazonaws.com',
    subDomain: window.location.host.split('.')[0],
    domain: 'consumer.clickdealer.dev',

    gtmContainerId: 'GTM-WC3R83P',
    gtmEnvironmentId: '3',
    gtmEnvironmentAuth: 'daQVTRQ89GlRtgaFdU2S1A',
};
